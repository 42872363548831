header{
    background-color: #1e1e1e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    color: white;
    height: 90px;
    width: 100vw;
    overflow: scroll;

}

#nav-logo{
    height: 35px;
    filter: invert(1);
}

.nav-link{
    color: #fff;
    text-decoration: none;
    margin: 0 1rem;
    font-size: 18px;
    font-family: 'DolceVita';
}