input[type=date]{
    font-family: sans-serif;
}

.event-input{
    width: 90%;
    margin: 1rem auto;
    height: 50px;
    padding-left: 10px;
    border-radius: 5px;
    border: unset;
    background-color: #fafafa;
}

.unassigned, .normal, .pending, .confirmed{
    height: 25px;
    border: unset;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.unassigned{
    background-color: crimson;
}

.normal{
    background-color: #1e1e1e;
}

.pending{
    background-color: #ffcc00;

}

.confirmed{
    background-color: green;

}