*{
  box-sizing: border-box;
  font-family: 'Proxima';
}

.outlet{
  padding: 1rem;
  background-color: #fafafa;
  min-height: calc(100vh - 90px);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

button:hover, a:hover{
  cursor: pointer;
}

.otf-btn{
  border: 3px solid #1e1e1e;
  padding: 10px;
  min-width: 150px;
  max-width: 250px;
  height: 40px;
  background-color: unset;
  font-family: 'DolceVita';
  margin: 0.5rem auto;
}

.inverted-btn{
  border: unset;
  padding: 10px;
  min-width: 150px;
  max-width: 250px;
  height: 40px;
  background-color: #1e1e1e;
  color: white;
  font-family: 'DolceVita';
  margin: 0.5rem auto;

}

.om-input{
  width: 450px;
  max-width: 90vw;
  margin: 0.5rem auto;
  border: unset;
  padding-left: 10px;
  border-radius: 5px;
  background-color: #dfdfdf;
  font-size: 16px;
  height: 50px;
}

#overview-table th{
  padding: 10px;
  text-align: left;
}

#overview-table td{
  padding: 10px;
  text-align: right;
}

label{
  font-size: 0.8rem;
}

@font-face {
  font-family: 'Bebas';
  src: local('Bebas'), url(./Assets/fonts/Bebas.otf) format('opentype');
}
@font-face {
  font-family: 'Proxima';
  src: local('Proxima'), url(./Assets/fonts/proxima.otf) format('opentype');
}
@font-face {
  font-family: 'DolceVita';
  src: local('DolceVita'), url(./Assets/fonts/DolceVita.ttf) format('truetype');
}

