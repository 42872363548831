#classes-table{
    
    max-width: 95vw;
    margin: 1rem auto;
    display: block;
}

#classes-table th, #classes-table td {
    padding: 10px;
    text-align: left;
}

#classes-table tr:hover{
    cursor: pointer;
}

.classes-input{
    height: 50px;
    margin: 10px 0;
    border: unset;
    background-color: #fafafa;
    padding-left: 10px;
    font-size: 16px;
    border-radius: 5px;
}

.modal-close-btn{
    border: 2px solid #dfdfdf;
    height: 40px;
    width: 100px;
    margin: 10px auto;
    border-radius: 5px;
    background-color: unset;
    color: grey;

}

input[type=time]{
    font-family: sans-serif;
}